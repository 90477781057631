<template>
    <div>
        <base-header class="pb-1">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0">Relatório de Movimento Financeiro</h6>
                </div>
            </div>
        </base-header>

        <div class="container-fluid mt--6">
            <form>
                <card class="d-print-none">
                    <h3 slot="header" class="mb-0">Relatório de Movimento Financeiro</h3>
                    <div class="form-row mt-3">
                        <div class="col-md-6">
                            <base-input addon-left-icon="ni ni-calendar-grid-58" label="Data Inicio">
                                <flat-picker slot-scope="{focus, blur}" @on-open="focus" @on-close="blur"
                                    :config="{ allowInput: true, dateFormat: 'd/m/Y', altFormat: 'M j, Y' }"
                                    class="form-control datepicker" placeholder="Data Inicial"
                                    v-model="reportFilter.dateBegin">
                                </flat-picker>
                            </base-input>
                        </div>
                        <div class="col-md-6">
                            <base-input addon-left-icon="ni ni-calendar-grid-58" label="Data Final">
                                <flat-picker slot-scope="{focus, blur}" @on-open="focus" @on-close="blur"
                                    :config="{ allowInput: true, dateFormat: 'd/m/Y', altFormat: 'M j, Y' }"
                                    class="form-control datepicker" placeholder="Data Final"
                                    v-model="reportFilter.dateEnd">
                                </flat-picker>
                            </base-input>
                        </div>
                        <div class="col-md-6"
                            v-if="getProfile() == 'MANAGER' || getProfile() == 'SUPERVISOR' || getProfile() == 'ADMIN'">
                            <base-input label="Responsável" required name="responsavel">
                                <el-select v-model="reportFilter.userResponsiblePayment" filterable placeholder="Todos">
                                    <el-option v-for="option in users" :key="option.id" :label="option.name"
                                        :value="option.id">
                                        {{ option.name }}
                                    </el-option>
                                </el-select>
                            </base-input>
                        </div>
                    </div>
                    <div class="col-md-12 text-right mb-3">
                        <base-button type="secundary" native-type="button" @click="printReport"><i
                                class="fas fa-print mr-1"></i>Imprimir</base-button>
                        <base-button type="secundary" native-type="button" @click="exportReport"><i
                                class="far fa-file-excel mr-1"></i>Exportar</base-button>
                        <base-button type="secundary" native-type="button" @click="exportDetailReport"><i
                                class="fas fa-file-excel mr-1"></i>Detalhado</base-button>
                        <base-button type="primary" native-type="button" @click="filterReport"><i
                                class="fas fa-funnel-dollar mr-1"></i> Filtrar</base-button>
                    </div>
                </card>

                <div class="col-md-12">
                    <card>
                        <h3 slot="header">Movimentação de Entrada</h3>
                        <h5 class="text-center mb-2">Encontrados <strong>{{ registryFound }}</strong> faturas</h5>
                        <table class="table table-responsive font-tabela-peq">
                            <thead class="thead">
                                <tr>
                                    <th scope="col">Fatura</th>
                                    <th scope="col">Competência</th>
                                    <th scope="col">Tipo de Fatura</th>
                                    <th scope="col">Tipo de Pagamento</th>
                                    <th scope="col">Cliente</th>
                                    <th scope="col">Dt. Pagamento</th>
                                    <th scope="col">Dt. Vencimento</th>
                                    <th scope="col">Num. Boleto</th>
                                    <th scope="col">Valor Pago</th>
                                    <th scope="col">NFE</th>
                                    <th scope="col">Autorização Cartão</th>
                                    <th scope="col">Responsável</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="report in reportResponse.details" :key="report.invoiceId">
                                    <td>{{ report.invoiceId }}</td>
                                    <td>{{ report.competenceBegin }} a {{ report.competenceEnd }}</td>
                                    <td>
                                        <span v-if="report.invoiceType == 'DEFAULT'">Mensalidade</span>
                                        <span v-if="report.invoiceType == 'EXTRA'">Avulso</span>
                                        <span v-if="report.invoiceType == 'AGREEMENT'">Acordo</span>
                                        <span v-if="report.invoiceType == 'DEPENDENT'">Dependente</span>
                                    </td>
                                    <td>
                                        <span v-if="report.paymentType == 'CREDIT_CARD'">Cartão/Cred</span>
                                        <span v-if="report.paymentType == 'DEBIT_CARD'">Cartão/Deb</span>
                                        <span v-if="report.paymentType == 'TICKET'">Boleto</span>
                                        <span v-if="report.paymentType == 'TICKETS'">Boleto/Carnê</span>
                                        <span v-if="report.paymentType == 'DEBIT_CARD_LOCAL'">Cartão/Deb (Maq)</span>
                                        <span v-if="report.paymentType == 'CREDIT_CARD_LOCAL'">Cartão/Cred (Maq)</span>
                                        <span v-if="report.paymentType == 'MONEY'">Dinheiro</span>
                                    </td>
                                    <td><a href="javascript:void(0);"
                                            @click="openSubscription(report.subscriptionId)">{{ report.holderName }}</a>
                                    </td>
                                    <td>{{ report.paymentDate }}</td>
                                    <td>{{ report.dueDate }}</td>
                                    <td>
                                        <span
                                            v-if="report.paymentType == 'TICKET' || report.paymentType == 'TICKETS'">{{ report.transactionId }}</span>
                                        <span
                                            v-if="report.paymentType != 'TICKET' && report.paymentType != 'TICKETS'">-</span>
                                    </td>
                                    <td>{{ report.amountPaidFmt }}</td>
                                    <td>
                                        <span v-if="report.nfeNumber != null">{{ report.nfeNumber }}</span>
                                        <span v-if="report.nfeNumber == null">-</span>
                                    </td>
                                    <td>
                                        <span
                                            v-if="report.authorizationCode != null">{{ report.authorizationCode }}</span>
                                        <span v-if="report.authorizationCode == null">-</span>
                                    </td>
                                    <td>
                                        {{ report.userResponsable }}
                                    </td>
                                </tr>
                                <tr v-show="reportResponse.details.length == 0">
                                    <td colspan="8">
                                        <center> Nenhuma informação disponível no relatório para o período informado.
                                        </center>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </card>
                </div>

                <div class="row">
                    <div class="col-md-4" v-if="reportResponse.totalDefault != null">
                        <card>
                            <h4>Totalização Mensalidade</h4>

                            <p class="mt-2 text-center"><i>Pagamentos Online</i></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="far fa-credit-card mr-2 mt-1"></i> Cartão de Crédito :
                                <strong>{{ reportResponse.totalDefault.totalCreditCardFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-credit-card mr-2"></i> Cartão de Débito :
                                <strong>{{ reportResponse.totalDefault.totalDebitCardFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-money-check mr-2"></i> Boleto/Carnê :
                                <strong>{{ reportResponse.totalDefault.totalTicketsFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-money-check-alt mr-2"></i> Boleto :
                                <strong>{{ reportResponse.totalDefault.totalTicketFmt }}</strong></p>

                            <p class="mt-2 text-center"><i>Pagamentos Locais</i></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-calculator mr-2 mt-1"></i> Cartão de Crédito (Maq):
                                <strong>{{ reportResponse.totalDefault.totalCreditCardLocalFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-calculator mr-2"></i> Cartão de Débito (Maq) :
                                <strong>{{ reportResponse.totalDefault.totalDebitCardLocalFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-money-bill mr-2"></i> Dinheiro :
                                <strong>{{ reportResponse.totalDefault.totalMoneyFmt }}</strong></p>
                            <hr>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">=</span> <i
                                    class="fas fa-dollar-sign mr-2"></i> Total :
                                <strong>{{ reportResponse.totalDefault.totalFmt }}</strong></p>
                        </card>
                    </div>
                    <div class="col-md-4" v-if="reportResponse.totalExtras != null">
                        <card>
                            <h4>Totalização Extras</h4>

                            <p class="mt-2 text-center"><i>Pagamentos Online</i></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="far fa-credit-card mr-2 mt-1"></i> Cartão de Crédito :
                                <strong>{{ reportResponse.totalExtras.totalCreditCardFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-credit-card mr-2"></i> Cartão de Débito :
                                <strong>{{ reportResponse.totalExtras.totalDebitCardFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-money-check mr-2"></i> Boleto/Carnê :
                                <strong>{{ reportResponse.totalExtras.totalTicketsFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-money-check-alt mr-2"></i> Boleto :
                                <strong>{{ reportResponse.totalExtras.totalTicketFmt }}</strong></p>

                            <p class="mt-2 text-center"><i>Pagamentos Locais</i></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-calculator mr-2 mt-1"></i> Cartão de Crédito (Maq):
                                <strong>{{ reportResponse.totalExtras.totalCreditCardLocalFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-calculator mr-2"></i> Cartão de Débito (Maq) :
                                <strong>{{ reportResponse.totalExtras.totalDebitCardLocalFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-money-bill mr-2"></i> Dinheiro :
                                <strong>{{ reportResponse.totalExtras.totalMoneyFmt }}</strong></p>

                            <hr>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">=</span> <i
                                    class="fas fa-dollar-sign mr-2"></i> Total :
                                <strong>{{ reportResponse.totalExtras.totalFmt }}</strong></p>
                        </card>
                    </div>
                    <div class="col-md-4" v-if="reportResponse.totalAgreement != null">
                        <card>
                            <h4>Totalização Acordo</h4>

                            <p class="mt-2 text-center"><i>Pagamentos Online</i></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="far fa-credit-card mr-2 mt-1"></i> Cartão de Crédito :
                                <strong>{{ reportResponse.totalAgreement.totalCreditCardFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-credit-card mr-2"></i> Cartão de Débito :
                                <strong>{{ reportResponse.totalAgreement.totalDebitCardFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-money-check mr-2"></i> Boleto/Carnê :
                                <strong>{{ reportResponse.totalAgreement.totalTicketsFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-money-check-alt mr-2"></i> Boleto :
                                <strong>{{ reportResponse.totalAgreement.totalTicketFmt }}</strong></p>

                            <p class="mt-2 text-center"><i>Pagamentos Locais</i></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-calculator mr-2 mt-1"></i> Cartão de Crédito (Maq):
                                <strong>{{ reportResponse.totalAgreement.totalCreditCardLocalFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-calculator mr-2"></i> Cartão de Débito (Maq) :
                                <strong>{{ reportResponse.totalAgreement.totalDebitCardLocalFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-money-bill mr-2"></i> Dinheiro :
                                <strong>{{ reportResponse.totalAgreement.totalMoneyFmt }}</strong></p>

                            <hr>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">=</span> <i
                                    class="fas fa-dollar-sign mr-2"></i> Total :
                                <strong>{{ reportResponse.totalAgreement.totalFmt }}</strong></p>
                        </card>
                    </div>

                    <div class="col-md-12" v-if="reportResponse.totalGeneral != null">
                        <card>
                            <h4>Totalização Geral (Mensalidade + Extras + Acordos)</h4>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="far fa-credit-card mr-2 mt-4"></i> Cartão de Crédito :
                                <strong>{{ reportResponse.totalGeneral.totalCreditCardFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-credit-card mr-2"></i> Cartão de Débito :
                                <strong>{{ reportResponse.totalGeneral.totalDebitCardFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-money-check mr-2"></i> Boleto/Carnê :
                                <strong>{{ reportResponse.totalGeneral.totalTicketsFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-money-check-alt mr-2"></i> Boleto :
                                <strong>{{ reportResponse.totalGeneral.totalTicketFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-calculator mr-2 "></i> Cartão de Crédito (Maq):
                                <strong>{{ reportResponse.totalGeneral.totalCreditCardLocalFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-calculator mr-2"></i> Cartão de Débito (Maq) :
                                <strong>{{ reportResponse.totalGeneral.totalDebitCardLocalFmt }}</strong></p>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">+</span> <i
                                    class="fas fa-money-bill mr-2"></i> Dinheiro :
                                <strong>{{ reportResponse.totalGeneral.totalMoneyFmt }}</strong></p>
                            <hr>
                            <p class="text-nowrap font-tabela-peq"><span class="mr-2">=</span> <i
                                    class="fas fa-dollar-sign mr-2"></i> Total :
                                <strong>{{ reportResponse.totalGeneral.totalFmt }}</strong></p>
                        </card>
                    </div>

                </div>

            </form>
        </div> <!--- fecha conteudo -->

    </div>
</template>

<script>
import { Select, Option } from 'element-ui'
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import FileSaver from 'file-saver'

export default {
    components: {
        [Select.name]: Select,
        [Option.name]: Option,
        flatPicker
    },
    created() {
        this.loadUsers()
    },
    data() {
        return {
            registryFound: 0,
            reportResponse: {
                details: []
            },
            reportFilter: {
                dateBegin: null,
                dateEnd: null,
                subscriptionId: null,
                userResponsiblePayment: null
            },
            users: []
        }
    },
    methods: {
        getProfile() {
            return localStorage.getItem("profile")
        },
        loadUsers() {
            if (this.getProfile() == 'MANAGER' || this.getProfile() == 'SUPERVISOR' || this.getProfile() == 'ADMIN') {
                this.$clubApi.get('/user/list/all')
                    .then((response) => {
                        this.users = response.data.object
                        this.users.push({ id: 0, name: "SISTEMA" })
                    }).catch((error) => {
                        this.$notify({ type: 'warning', message: error.response.data.msg })
                    }).finally(() => {
                        NProgress.done()
                    })
            }
        },
        printReport() {
            window.print()
        },
        exportReport() {
            this.$clubApi.post('/report/motion/export', this.reportFilter, {
                responseType: 'blob'
            }).then((response) => {
                var blob = new Blob([response.data], {
                    type: 'application/vnd.ms-excel'
                });
                FileSaver.saveAs(blob, 'relatorio-movimento.xlsx');
            }).catch((error) => {
                this.$notify({ type: 'warning', message: error.response.data.msg })
            }).finally(() => {
                NProgress.done()
            })
        },
        exportDetailReport() {
            this.$clubApi.post('/report/motion/exportDetail', this.reportFilter, {
                responseType: 'blob'
            }).then((response) => {
                var blob = new Blob([response.data], {
                    type: 'application/vnd.ms-excel'
                });
                FileSaver.saveAs(blob, 'relatorio-movimento-detalhado.xlsx');
            }).catch((error) => {
                this.$notify({ type: 'warning', message: error.response.data.msg })
            }).finally(() => {
                NProgress.done()
            })
        },
        openSubscription(id) {
            location.href = '/assinatura/' + id
        },
        filterReport() {
            this.$clubApi.post('/report/motion', this.reportFilter)
                .then((response) => {
                    this.reportResponse = response.data.object
                    if (response.data.object != null && response.data.object.details != null) {
                        this.registryFound = response.data.object.details.length
                    } else {
                        this.registryFound = 0
                    }
                }).catch((error) => {
                    this.$notify({ type: 'warning', message: error.response.data.msg })
                }).finally(() => {
                    NProgress.done()
                })
        }
    }
};
</script>

<style></style>